<template>
    <div class="block-text" v-html="content.text || ''"></div>
</template>

<script>
    export default {
        name: "BlockText",
        props: {
            content: {}
        }
    }
</script>

<style scoped>

</style>